import { LoadingButton } from "@mui/lab";
import { Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { moneyMask } from "@utils/moneymask";
import LadingCardsLayoutTwo from "components/lendingCards/layoutTwo"
import { YouTubePlayer } from "components/youtubePlayer";
import useProposal from "context/proposal";
import useMediasQuerys from "hooks/mediasQuery";
import { colors } from "theme";
import KitImg from "assets/png/kit.png"
import BlackFridayImg from "assets/png/black.png"
import VaptPostImg from "assets/png/vaptpost img.png"
import { Link } from "react-router-dom";
import { saveAs } from "file-saver"
import useAuth from "context/auth";

export const SimulatorWithLend = ({
    onClickCommissionRequest,
    calcPreApproved,
    limitError,
    loadingPreApproved,
    onChangeText
}: {
    onClickCommissionRequest: () => void,
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    calcPreApproved: () => void;
    loadingPreApproved: boolean;
    limitError: string;
}) => {
    const { User } = useAuth()
    const { palette } = useTheme()
    const { matchesMaxMobile } = useMediasQuerys()
    const matchsMd = !useMediaQuery('(min-width:1530px)')
    const { cardLimit, parcels } = useProposal();

    return (
        <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap-reverse"}
            spacing={matchsMd ? 0 : 1}
        >
            <Grid
                item
                md={12}
                xl={8}
                border={`2px solid #4C9FDB`}
                p={"20px 40px"}
                borderRadius={"31px"}
                bgcolor={"#E4E4E4"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                gap={2}
            >
                <LadingCardsLayoutTwo
                    onClickCommissionRequest={onClickCommissionRequest}
                />

                <Grid
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Typography
                        fontWeight={800}
                        fontSize={32}
                        textAlign={"center"}
                    >
                        Simule e inicie seu empréstimo
                    </Typography>

                    <Typography
                        fontWeight={500}
                        fontSize={13}
                        textAlign={"center"}
                    >
                        De <strong>R$ 300,00</strong> a <strong>R$ 5.000,00</strong>. Dinheiro na conta (em até 1 hora útil)
                    </Typography>
                </Grid>

                <Grid
                    width={"100%"}
                    container
                    gap={1}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <TextField
                        sx={{
                            maxWidth: 359,
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                height: '37px',
                                padding: 0,
                                '& input': {
                                    padding: '8px 12px',
                                    color: '#4A4A4A',
                                    backgroundColor: '#E4E4E4',
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: 'black',
                                fontSize: '12px',
                                lineHeight: '1.2',
                                padding: '0 4px',
                                maxWidth: 'fit-content',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            },
                            '& .MuiInputLabel-shrink': {
                                transform: 'translate(10px, -3.5px) scale(0.75)',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                top: '-4px',
                                legend: {
                                    width: '130px',
                                },
                            },
                        }}
                        variant="outlined"
                        label="Digite o saldo correto aqui"
                        value={moneyMask(cardLimit)}
                        onChange={onChangeText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                calcPreApproved()
                            }
                        }}
                        error={!!limitError}
                        helperText={limitError}
                    />
                    <LoadingButton
                        disabled={cardLimit === '0'}
                        sx={{
                            height: 37,
                            width: 193,
                            fontSize: 24,
                            color: '#F1F1F1',
                            fontWeight: 'bold',
                            backgroundColor: '#B6C931',
                            borderRadius: '6px',
                            textShadow: '0px 2px 4px #00000080',

                        }}
                        loading={loadingPreApproved}
                        onClick={calcPreApproved}
                        variant="contained"
                        disableElevation>
                        {!loadingPreApproved && "Simular"}
                    </LoadingButton>
                </Grid>

                <Typography
                    fontWeight={500}
                    fontSize={14}
                    textAlign={"center"}
                    maxWidth={600}
                >
                    Para compras no cartão de crédito do cliente, insira o valor acima e certifique-se de digitar o <strong>VALOR EXATO DO SALDO DISPONÍVEL</strong> para evitar o bloqueio do cartão.
                </Typography>

            </Grid>

            <Grid
                item
                md={12}
                xl={4}
            >
                <Grid
                    width={"100%"}
                >
                    <YouTubePlayer
                        apiKey="AIzaSyDkc2K0MUVxoT3M6HTxI0ORiZdKlk9HpnA"
                        playlistId="PLigzN2Gk7jkWvyajnHtD8r99YuOjo3B1l"
                        key={"youtube-player"}
                    />
                </Grid>
                <Grid
                    container
                    spacing={2}
                    mt={2}
                >
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        component={"button"}
                        sx={{
                            backgroundColor: 'transparent',
                            p: 0,
                            m: 0,
                            border: 0,
                            cursor: 'pointer'
                        }}
                    >
                        <Link to={"https://youtu.be/2H0zpQmoDZw"} target="_blank">
                            <img
                                src={VaptPostImg}
                                alt="Vaptpost banner"
                                style={{
                                    height: 'auto',
                                    // minWidth: '250px',
                                    width: '100%',
                                    borderRadius: '31px',
                                    border: '2px solid #4C9FDB'
                                }}
                            />
                        </Link>
                    </Grid>
                    {/* <Grid
                        item
                        sm={6}
                        xs={12}
                        component={"button"}
                        sx={{
                            backgroundColor: 'transparent',
                            p: 0,
                            m: 0,
                            border: 0,
                            cursor: 'pointer'
                        }}
                        onClick={() => saveAs(User?.space === "sales_counter" ? "https://front-source-assets.s3.sa-east-1.amazonaws.com/smartbank/documents/FILIAL+-+Roteiro+Operacional+Ayude+..pdf" : 'https://front-source-assets.s3.sa-east-1.amazonaws.com/pdv/documents/Roteiro+Operacional+Ayude.pdf', "Roteiro operacional.pdf")}
                    >
                        <img
                            src={KitImg}
                            alt="Kit parceiros banner"
                            style={{
                                height: 'auto',
                                // minWidth: '250px',
                                width: '100%',
                                borderRadius: '31px',
                                border: '2px solid #4C9FDB'
                            }}
                        />
                    </Grid> */}
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        component={"button"}
                        sx={{
                            backgroundColor: 'transparent',
                            p: 0,
                            m: 0,
                            border: 0,
                            cursor: 'pointer'
                        }}
                    >
                        <Link to={"https://escoladocredito.notion.site/Pack-de-artes-edit-veis-Corban-Black-1300805e624b8063a3e6f8026268aacd?pvs=4"} target="_blank">

                            <img
                                src={BlackFridayImg}
                                alt="black friday banner"
                                style={{
                                    height: 'auto',
                                    // minWidth: '250px',
                                    width: '100%',
                                    borderRadius: '31px',
                                    border: '2px solid #4C9FDB'
                                }}
                            />
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}