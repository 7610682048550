import { IGestorPermissions } from "pages/app/gestor/interfaces";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { RemoteServices } from "service";
import { UserService } from "service/storage";
import UserStorage from "service/storage/user";
import storageLocales from "utils/storageLocales";
import URLSBases from "utils/urlBases";

interface MyTaxProps {
    "sale_table": string,
    "commission_tax": number,
    "commission_rate": number
}

interface ContextProvider {
    AlreadySigned: () => void;
    setAlreadySigned(a: boolean): void;
    alreadySigned: boolean;
    getMyTax: () => void;
    myTax: MyTaxProps[];
    cardLimit: string;
    setCardLimit(a: string): void;
    User: UserProp | null;
    AuthLoading: boolean;
    setAuthLoading(a: boolean): void;
    Authentication: (token: string) => void;
    UserOperator: UserProps | null;
    logout: () => void;
    UserOperatorSales: SalesCounterProps | null;
    openModalUpdate: boolean,
    setOpenModalUpdate: (a: boolean) => void;
    canRefuseUpdate: boolean,
    setCanRefuseUpdate: (a: boolean) => void;
    defaultUser: UserProps | null;
    setDefaultUser: (a: UserProps) => void;
}

export interface UserProps {
    tradeName?: string,
    responsibleName?: string,
    email?: string,
    owerId?: number,
    licensedId?: number;
    user?: any;
    permissions?: IGestorPermissions,
    owner?: any,
    id?: number
}

export interface SalesCounterProps {
    "identificationName": string,
    "tradeName": string,
    "identification_name": string,
    "trade_name": string,
    "responsible_name": string,
    "email": string,
    "owerId": number,
    "owner_id": number,
    "responsible_cpf": string,
    "cnpj": string | null,
    "cellphone": string,
    "city": string,
    "state": string,
    "district": string,
    "postal_code": string,
    "address": string,
    "address_number": string,
    "address_complement": string | null,
    "owner_trade_name": string,
    "id": number

}

export interface UserProp {
    "access_token": string;
    "refresh_token": string;
    "permissions": [],
    "sub": string;
    "username": string;
    "profile": number,
    "space": "pdv" | "sales_counter" | "gestor";
    "app_id": number,
    "grant_type": string;
    "aud": string;
}
export const AuthManager = createContext<ContextProvider>({} as ContextProvider);
const useAuth = () => useContext(AuthManager);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {

    const {
        search
    } = useLocation()

    const [alreadySigned, setAlreadySigned] = useState(false)
    const [myTax, setMyTax] = useState<MyTaxProps[]>([])

    const [cardLimit, setCardLimit] = useState<string>('')

    const [User, setUser] = useState<UserProp | null>(() => {
        const myUser = localStorage.getItem(storageLocales.TCUser)
        if (myUser) {
            return JSON.parse(myUser)
        }
        return null
    })

    const [defaultUser, setDefaultUser] = useState<UserProps | null>(null)

    const [UserOperator, setUserOperator] = useState<UserProps | null>(null)
    const [UserGestor, setUserGestor] = useState<UserProps | null>(null)


    const [UserOperatorSales, setUserOperatorSales] = useState<SalesCounterProps | null>(null)

    const [AuthLoading, setAuthLoading] = useState<boolean>(false)

    const [openModalUpdate, setOpenModalUpdate] = useState(false)
    const [canRefuseUpdate, setCanRefuseUpdate] = useState(false)

    const AlreadySigned = () => {
        RemoteServices.Contract().then(({ response, status }) => {
            if (response?.alreadySigned) {
                setAlreadySigned(response.alreadySigned)
                return response.alreadySigned
            }
            return false
        })
    }

    const logout = () => {
        RemoteServices.auth.Logout()
            .finally(() => {
                localStorage.removeItem(storageLocales.TCtoken);
                localStorage.removeItem(storageLocales.TCUser)
                window.location.replace(URLSBases.LOGIN)
            })
    }

    const Authentication = async (token: string) => {
        setAuthLoading(true)
        RemoteServices.auth.Login(token).then(async ({ response }) => {
            if (response?.access_token) {
                setUser(response)
                UserStorage.saveUserTc(response)
                UserStorage.saveToken(response?.access_token)
                await getUpdateSalesCounter()
            }
            else {
                localStorage.removeItem(storageLocales.TCtoken);
                localStorage.removeItem(storageLocales.TCUser)
                window.location.replace(URLSBases.LOGIN)
            }
        }).finally(() => {
            setAuthLoading(false)
        })
    }



    useEffect(() => {
        if (search?.slice(1, 3) === 'r=') {
            Authentication(search?.slice(3, search.length))
        } else {
            if (!UserStorage.getUserTc() && !window.location.href.includes('auto-cadastro')) {
                document.location.replace(URLSBases.LOGIN)
            }
        }
    }, [search])

    const getMeSales = async () => {
        RemoteServices.auth.MeSale().then(({ data }) => {
            if (data?.data) {
                setUserOperatorSales({
                    ...data?.data.sales_counter,
                    responsibleName: data?.data.sales_counter.responsible_name,
                    owner_trade_name: data?.data?.owner?.trade_name ?? 'não tem'
                })
                console.log(data.data)
            }
        })
    }

    useEffect(() => {
        if (User && !AuthLoading) {
            if (User?.space === 'pdv') {
                getMe()
                AlreadySigned()
            } else if (User?.space === 'sales_counter') {
                getMeSales()
            } else {
                getMeGestor()
            }
            getMyTax()
        }

    }, [User, AuthLoading])

    useEffect(() => {

        if (User && (UserOperator || UserOperatorSales || UserGestor)) {
            switch (User.space) {
                case 'pdv':
                    setDefaultUser(UserOperator)
                    break;
                case 'sales_counter':
                    setDefaultUser(UserOperatorSales)
                    break;
                case 'gestor':
                    setDefaultUser(UserGestor)
                    break;
                default:
                    break;
            }
        }
    }, [User, UserOperator, UserOperatorSales, UserGestor])


    const getMyTax = async () => {
        RemoteServices.proposals.CommissionTax(User?.space === "sales_counter" ? User.sub : "")
            .then(({ response }) => {
                if (response?.result) {
                    setMyTax(response.result)
                }
            })
    }

    const getMe = async () => {
        RemoteServices.auth.Me()
            .then(({ response }) => {
                if (response?.data) {
                    const UserInfos = {
                        identificationName: response?.data?.owner?.responsible_name,
                        tradeName: response?.data?.owner?.trade_name,
                        responsibleName: response?.data?.owner?.responsible_name,
                        email: response?.data?.owner?.email,
                        owerId: response?.data?.owner.id,
                        licensedId: response?.data?.owner?.affiliate_id
                    }
                    setUserOperator(UserInfos)
                    UserService.saveUser(UserInfos)
                }
            })
    }

    const getMeGestor = async () => {
        RemoteServices.auth.MeGestor()
        .then(({ response }) => {
                console.log(response)
                if (response?.id) {
                    const UserInfos = {
                        id: response.id,
                        responsibleName: response.name,
                        email: response.email,
                        owerId: response.owner_id,
                        permissions: response.permissions,
                        owner: response.owner
                    } as UserProps
                    setUserGestor(UserInfos)
                    UserService.saveUser(UserInfos)
                }
            })
    }

    const getUpdateSalesCounter = async () => {
        await RemoteServices.filiais.GetUpdate().then(({ response, status }) => {
            console.log(response, status)
            if (response?.result?.updated === true || response?.error === "Não autorizado") {
                window.location.href = '/'
            } else {
                setCanRefuseUpdate(response?.result?.can_refuse)
                setOpenModalUpdate(true)
            }
        })
    }

    return (
        <AuthManager.Provider value={{
            AlreadySigned,
            alreadySigned,
            setAlreadySigned,
            getMyTax,
            myTax,
            cardLimit,
            setCardLimit,
            User,
            AuthLoading,
            setAuthLoading,
            Authentication,
            UserOperator,
            logout,
            UserOperatorSales,
            openModalUpdate,
            setOpenModalUpdate,
            canRefuseUpdate,
            setCanRefuseUpdate,
            defaultUser,
            setDefaultUser
        }}>
            {children}
        </AuthManager.Provider>
    )
}


export default useAuth;